import { createSlice } from '@reduxjs/toolkit';

const icgmsuserSlice = createSlice({
  name: 'icgmsuser',
  initialState: {
    NameError: '',
    ReportingError: '',
    AddressError: '',
    CityError: '',
    StateError: '',
    ZoneError: '',
    CountryError: '',
    PincodeError: '',
    AadhaarcardError: '',
    AadhaarcopyError: '',
    PancardError: '',
    PancopyError: '',
    DrivinglicensetypeError: '',
    DrivinglicensevalidityError: '',
    DrivinglicensecopyError: '',
    GridNameError: '',
    GridcategoryError: '',
    GridtypeError: '',
    GridlicencenumberError: '',
    GridlicencecopyError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    GridgeolocationError: '',
    PermittedusersError: '',
    EmpidError: '',
    WhatsappError: '',
    EmailError: '',
    MobileError: '',
    Mobile1Error: '',
    GenderError: '',
    GeolocationError: '',
    PassportnoError: '',
    PassportcopyError: '',
    PassportvalidityError: '',
    SurveyorlicenceError: '',
    SurveyorlicencecopyError: '',
    SurveyorlicencenumberError: '',
    SelectproductsError: ''
  },
  reducers: {
    setSurveyorlicenceError: (state, action) => {
      state.SurveyorlicenceError = { name: action.payload };
    },
    clearSurveyorlicenceError: (state) => {
      state.SurveyorlicenceError = {};
    },
    setSurveyorlicencecopyError: (state, action) => {
      state.SurveyorlicencecopyError = { name: action.payload };
    },
    clearSurveyorlicencecopyError: (state) => {
      state.SurveyorlicencecopyError = {};
    },
    setSurveyorlicencenumberError: (state, action) => {
      state.SurveyorlicencenumberError = { name: action.payload };
    },
    clearSurveyorlicencenumberError: (state) => {
      state.SurveyorlicencenumberError = {};
    },
    setPassportnoError: (state, action) => {
      state.PassportnoError = { name: action.payload };
    },
    clearPassportnoError: (state) => {
      state.PassportnoError = {};
    },
    setPassportcopyError: (state, action) => {
      state.PassportcopyError = { name: action.payload };
    },
    clearPassportcopyError: (state) => {
      state.PassportcopyError = {};
    },
    setPassportvalidityError: (state, action) => {
      state.PassportvalidityError = { name: action.payload };
    },
    clearPassportvalidityError: (state) => {
      state.PassportvalidityError = {};
    },
    setGenderError: (state, action) => {
      state.GenderError = { name: action.payload };
    },
    clearGenderError: (state) => {
      state.GenderError = {};
    },
    setGeolocationError: (state, action) => {
      state.GeolocationError = { name: action.payload };
    },
    clearGeolocationError: (state) => {
      state.GeolocationError = {};
    },
    setWhatsappError: (state, action) => {
      state.WhatsappError = { name: action.payload };
    },
    clearWhatsappError: (state) => {
      state.WhatsappError = {};
    },
    setEmailError: (state, action) => {
      state.EmailError = { name: action.payload };
    },
    clearEmailError: (state) => {
      state.EmailError = {};
    },
    setMobileError: (state, action) => {
      state.MobileError = { name: action.payload };
    },
    clearMobileError: (state) => {
      state.MobileError = {};
    },
    setMobile1Error: (state, action) => {
      state.Mobile1Error = { name: action.payload };
    },
    clearMobile1Error: (state) => {
      state.Mobile1Error = {};
    },
    setNameError: (state, action) => {
      state.NameError = { name: action.payload };
    },
    clearNameError: (state) => {
      state.NameError = {};
    },
    setEmpidError: (state, action) => {
      state.EmpidError = { name: action.payload };
    },
    clearEmpidError: (state) => {
      state.EmpidError = {};
    },
    setReportingError: (state, action) => {
      state.ReportingError = { name: action.payload };
    },
    clearReportingError: (state) => {
      state.ReportingError = {};
    },
    setAddressError: (state, action) => {
      state.AddressError = { name: action.payload };
    },
    clearAddressError: (state) => {
      state.AddressError = {};
    },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload };
    },
    clearCityError: (state) => {
      state.CityError = {};
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload };
    },
    clearStateError: (state) => {
      state.StateError = {};
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload };
    },
    clearZoneError: (state) => {
      state.ZoneError = {};
    },
    setCountryError: (state, action) => {
      state.CountryError = { name: action.payload };
    },
    clearCountryError: (state) => {
      state.CountryError = {};
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload };
    },
    clearPincodeError: (state) => {
      state.PincodeError = {};
    },
    setAadhaarcardError: (state, action) => {
      state.AadhaarcardError = { name: action.payload };
    },
    clearAadhaarcardError: (state) => {
      state.AadhaarcardError = {};
    },
    setAadhaarcopyError: (state, action) => {
      state.AadhaarcopyError = { name: action.payload };
    },
    clearAadhaarcopyError: (state) => {
      state.AadhaarcopyError = {};
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload };
    },
    clearPancardError: (state) => {
      state.PancardError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setDrivinglicensetypeError: (state, action) => {
      state.DrivinglicensetypeError = { name: action.payload };
    },
    clearDrivinglicensetypeError: (state) => {
      state.DrivinglicensetypeError = {};
    },
    setDrivinglicensevalidityError: (state, action) => {
      state.DrivinglicensevalidityError = { name: action.payload };
    },
    clearDrivinglicensevalidityError: (state) => {
      state.DrivinglicensevalidityError = {};
    },
    setDrivinglicensecopyError: (state, action) => {
      state.DrivinglicensecopyError = { name: action.payload };
    },
    clearDrivinglicensecopyError: (state) => {
      state.DrivinglicensecopyError = {};
    },
    setGridNameError: (state, action) => {
      state.GridNameError = { name: action.payload };
    },
    clearGridNameError: (state) => {
      state.GridNameError = {};
    },
    setGridcategoryError: (state, action) => {
      state.GridcategoryError = { name: action.payload };
    },
    clearGridcategoryError: (state) => {
      state.GridcategoryError = {};
    },
    setGridtypeError: (state, action) => {
      state.GridtypeError = { name: action.payload };
    },
    clearGridtypeError: (state) => {
      state.GridtypeError = {};
    },
    setGridlicencenumberError: (state, action) => {
      state.GridlicencenumberError = { name: action.payload };
    },
    clearGridlicencenumberError: (state) => {
      state.GridlicencenumberError = {};
    },
    setGridlicencecopyError: (state, action) => {
      state.GridlicencecopyError = { name: action.payload };
    },
    clearGridlicencecopyError: (state) => {
      state.GridlicencecopyError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    },
    setSelectproductsError: (state, action) => {
      state.SelectproductsError = { name: action.payload };
    },
    clearSelectproductsError: (state) => {
      state.SelectproductsError = {};
    }
  }
});

export const {
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setDrivinglicensetypeError,
  clearDrivinglicensetypeError,
  setDrivinglicensevalidityError,
  clearDrivinglicensevalidityError,
  setDrivinglicensecopyError,
  clearDrivinglicensecopyError,
  setGridNameError,
  clearGridNameError,
  setGridcategoryError,
  clearGridcategoryError,
  setGridtypeError,
  clearGridtypeError,
  setGridlicencenumberError,
  clearGridlicencenumberError,
  setGridlicencecopyError,
  clearGridlicencecopyError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setSelectproductsError,
  clearSelectproductsError,
  setAadhaarcopyError,
  clearAadhaarcopyError,
  setAadhaarcardError,
  clearAadhaarcardError,
  setPincodeError,
  clearPincodeError,
  setCountryError,
  clearCountryError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setAddressError,
  clearAddressError,
  setEmpidError,
  clearEmpidError,
  setNameError,
  clearNameError,
  setMobileError,
  clearMobileError,
  setMobile1Error,
  clearMobile1Error,
  setWhatsappError,
  clearWhatsappError,
  setEmailError,
  clearEmailError,
  setGeolocationError,
  clearGeolocationError,
  setGenderError,
  clearGenderError,
  setPassportnoError,
  clearPassportnoError,
  setPassportcopyError,
  clearPassportcopyError,
  setPassportvalidityError,
  clearPassportvalidityError,
  setSurveyorlicenceError,
  clearSurveyorlicenceError,
  setSurveyorlicencenumberError,
  clearSurveyorlicencenumberError,
  setSurveyorlicencecopyError,
  clearSurveyorlicencecopyError,
  setReportingError,
  clearReportingError
} = icgmsuserSlice.actions;

export default icgmsuserSlice.reducer;
