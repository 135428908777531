// productSlice.js
import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    editMode: false,
    editedProductId: null,
    formData: {
      category: '',
      name: '',
      description: '',
      clientPrice: '',
      surveyorPrice: ''
    }
  },
  reducers: {
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setEditedProductId: (state, action) => {
      state.editedProductId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = {
        category: '',
        name: '',
        description: '',
        clientPrice: '',
        surveyorPrice: ''
      };
    }
  }
});

export const { setEditMode, setEditedProductId, setFormData, resetFormData } = productSlice.actions;
export const selectEditMode = (state) => state.product.editMode;
export const selectEditedProductId = (state) => state.product.editedProductId;
export const selectFormData = (state) => state.product.formData;

export default productSlice.reducer;
