import React from 'react';

function Loader() {
  return (
    <div>
      <span className='loader'></span>
    </div>
  );
}

export default Loader;
