import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import icgmsRegReducer from './icgmsRegSlice';
import authReducer from './authSlice';
import forgotReducer from './forgetSlice';
import locationReducer from './location/locationSlice';
import locationGridReducer from './location/locationListSlice';
import locationEditReducer from './location/locationEditSlice';
import productReducer from './product/productSlice';
import productListReducer from './product/productListSlice';
import surveyorReducer from '../redux/icgms/surveyorSlice';
import repairerReducer from '../redux/icgms/repairerSlice';
import userReducer from '../redux/client/user';
import clientReducer from '../redux/icgms/clientSlice';
import prilimReducer from '../redux/icgms/prilim';
import financierReducer from '../redux/icgms/financierSlice';
import icgmsuserReducer from '../redux/icgms/icgmsUserSlice';
import subscriptionReducer from '../redux/account/subscriptionSlice';
import billReducer from '../redux/account/billSlice';
import newclaimReduce from '../redux/client/newclaimSlice';
import claimintimationReduce from '../redux/client/claimintimationSlice';
import vechileReducer from '../redux/icgms/vechileSlice';

const rootReducer = combineReducers({
  form: icgmsRegReducer,
  auth: authReducer,
  forgotPassword: forgotReducer,
  location: locationReducer,
  locationEdit: locationEditReducer,
  locationGrid: locationGridReducer,
  product: productReducer,
  productList: productListReducer,
  surveyor: surveyorReducer,
  repairer: repairerReducer,
  user: userReducer,
  client: clientReducer,
  prilim_set: prilimReducer,
  icgmsuser: icgmsuserReducer,
  financier: financierReducer,
  subscription: subscriptionReducer,
  bill: billReducer,
  newclaim: newclaimReduce,
  claimintimation: claimintimationReduce,
  vehicle: vechileReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);
