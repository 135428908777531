import { createSlice } from '@reduxjs/toolkit';

const surveyorSlice = createSlice({
  name: 'vehicle',
  initialState: {
    ClassError: '',
    TypeofbodyError: '',
    ManufacturerError: '',
    MakeError: '',
    ModelError: '',
    VarientError: '',
    SeatingError: '',
    PowerError: '',
    CubicError: '',
    FuelError: '',
    LadenweightError: '',
    UnladenweightError: '',
    DrivinglicensetypeError: '',
    DrivinglicensevalidityError: '',
    DrivinglicensecopyError: '',
    GridClassError: '',
    GridcategoryError: '',
    GridtypeError: '',
    GridlicencenumberError: '',
    GridlicencecopyError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    GridgeolocationError: '',
    PermittedusersError: '',
    SelectproductsError: '',
    DrivinglicenceError: ''
  },
  reducers: {
    setDrivinglicenceError: (state, action) => {
      state.DrivinglicenceError = { name: action.payload };
    },
    clearDrivinglicenceError: (state) => {
      state.DrivinglicenceError = {};
    },
    setClassError: (state, action) => {
      state.ClassError = { name: action.payload };
    },
    clearClassError: (state) => {
      state.ClassError = {};
    },
    setTypeofbodyError: (state, action) => {
      state.TypeofbodyError = { name: action.payload };
    },
    clearTypeofbodyError: (state) => {
      state.TypeofbodyError = {};
    },
    setManufacturerError: (state, action) => {
      state.ManufacturerError = { name: action.payload };
    },
    clearManufacturerError: (state) => {
      state.ManufacturerError = {};
    },
    setMakesError: (state, action) => {
      state.MakeError = { name: action.payload };
    },
    clearMakesError: (state) => {
      state.MakeError = {};
    },
    setModelError: (state, action) => {
      state.ModelError = { name: action.payload };
    },
    clearModelError: (state) => {
      state.ModelError = {};
    },
    setVarientError: (state, action) => {
      state.VarientError = { name: action.payload };
    },
    clearVarientError: (state) => {
      state.VarientError = {};
    },
    setSeatingError: (state, action) => {
      state.SeatingError = { name: action.payload };
    },
    clearSeatingError: (state) => {
      state.SeatingError = {};
    },
    setPowerError: (state, action) => {
      state.PowerError = { name: action.payload };
    },
    clearPowerError: (state) => {
      state.PowerError = {};
    },
    setCubicError: (state, action) => {
      state.CubicError = { name: action.payload };
    },
    clearCubicError: (state) => {
      state.CubicError = {};
    },
    setFuelError: (state, action) => {
      state.FuelError = { name: action.payload };
    },
    clearFuelError: (state) => {
      state.FuelError = {};
    },
    setLadenweightError: (state, action) => {
      state.LadenweightError = { name: action.payload };
    },
    clearLadenweightError: (state) => {
      state.LadenweightError = {};
    },
    setUnladenweightError: (state, action) => {
      state.UnladenweightError = { name: action.payload };
    },
    clearUnladenweightError: (state) => {
      state.UnladenweightError = {};
    },
    setDrivinglicensetypeError: (state, action) => {
      state.DrivinglicensetypeError = { name: action.payload };
    },
    clearDrivinglicensetypeError: (state) => {
      state.DrivinglicensetypeError = {};
    },
    setDrivinglicensevalidityError: (state, action) => {
      state.DrivinglicensevalidityError = { name: action.payload };
    },
    clearDrivinglicensevalidityError: (state) => {
      state.DrivinglicensevalidityError = {};
    },
    setDrivinglicensecopyError: (state, action) => {
      state.DrivinglicensecopyError = { name: action.payload };
    },
    clearDrivinglicensecopyError: (state) => {
      state.DrivinglicensecopyError = {};
    },
    setGridClassError: (state, action) => {
      state.GridClassError = { name: action.payload };
    },
    clearGridClassError: (state) => {
      state.GridClassError = {};
    },
    setGridcategoryError: (state, action) => {
      state.GridcategoryError = { name: action.payload };
    },
    clearGridcategoryError: (state) => {
      state.GridcategoryError = {};
    },
    setGridtypeError: (state, action) => {
      state.GridtypeError = { name: action.payload };
    },
    clearGridtypeError: (state) => {
      state.GridtypeError = {};
    },
    setGridlicencenumberError: (state, action) => {
      state.GridlicencenumberError = { name: action.payload };
    },
    clearGridlicencenumberError: (state) => {
      state.GridlicencenumberError = {};
    },
    setGridlicencecopyError: (state, action) => {
      state.GridlicencecopyError = { name: action.payload };
    },
    clearGridlicencecopyError: (state) => {
      state.GridlicencecopyError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    },
    setSelectproductsError: (state, action) => {
      state.SelectproductsError = { name: action.payload };
    },
    clearSelectproductsError: (state) => {
      state.SelectproductsError = {};
    }
  }
});

export const {
  setDrivinglicenceError,
  clearDrivinglicenceError,
  setLadenweightError,
  clearLadenweightError,
  setUnladenweightError,
  clearUnladenweightError,
  setDrivinglicensetypeError,
  clearDrivinglicensetypeError,
  setDrivinglicensevalidityError,
  clearDrivinglicensevalidityError,
  setDrivinglicensecopyError,
  clearDrivinglicensecopyError,
  setGridClassError,
  clearGridClassError,
  setGridcategoryError,
  clearGridcategoryError,
  setGridtypeError,
  clearGridtypeError,
  setGridlicencenumberError,
  clearGridlicencenumberError,
  setGridlicencecopyError,
  clearGridlicencecopyError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setSelectproductsError,
  clearSelectproductsError,
  setFuelError,
  clearFuelError,
  setCubicError,
  clearCubicError,
  setPowerError,
  clearPowerError,
  setSeatingError,
  clearSeatingError,
  setVarientError,
  clearVarientError,
  setModelError,
  clearModelError,
  setMakesError,
  clearMakesError,
  setManufacturerError,
  clearManufacturerError,
  setClassError,
  clearClassError,
  setTypeofbodyError,
  clearTypeofbodyError
} = surveyorSlice.actions;

export default surveyorSlice.reducer;
