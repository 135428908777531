// Example: Inside your Redux slice file
import { createSlice } from '@reduxjs/toolkit';

const productListSlice = createSlice({
  name: 'productList',
  initialState: {
    gridData: [],
    sortOrder: { field: '', asc: true },
    filter: { category: '', name: '', perCasePriceForClient: '', perCasePriceForSurveyor: '' },
    currentPage: 1
  },
  reducers: {
    setGridData(state, action) {
      state.gridData = action.payload;
    },
    setSortOrder(state, action) {
      state.sortOrder = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    }
  }
});

export const { setGridData, setSortOrder, setFilter, setCurrentPage } = productListSlice.actions;

export default productListSlice.reducer;
