import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <Container fluid className='text-center'>
      <footer className='footer fixed-bottom bg-footer'>
        <div className='container-fluid'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-12 p-2 mx-auto'>
              <div className='copyright text-center text-sm text-muted text-lg-start mx-auto'>
                Copyrights © {new Date().getFullYear()},&nbsp;
                <a
                  href='#'
                  className='font-weight-bold'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  ICGMS.
                </a>{' '}
                All Rights Reserved. Design & Developed By{' '}
                <a href=''>
                  <i>TAGCS</i>
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}

export default Footer;
