import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
    name: 'client',
    initialState: {
    NameError: '',
    RegisterofficeError: '',
    CityError: '',
    StateError: '',
    ZoneError: '',
    PercaseError: '',
    PincodeError: '',
    GstnumberError: '',
    GstcopyError: '',
    PancardError: '',
    PancopyError: '',
    GridNameError: '',
    AadhaarcopyError: '',
    SlavendordateError: '',
    SlafromdateError: '',
    SlatodateError: '',
    AdvancepaidError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    SelectproductsError: '',
    GridgeolocationError: '',
    PermittedusersError: ''
    },
    reducers: {
    setNameError: (state, action) => {
      state.NameError = { name: action.payload };
    },
    clearNameError: (state) => {
      state.NameError = {};
    },
    setRegisterofficeError: (state, action) => {
      state.RegisterofficeError = { name: action.payload };
    },
    clearRegisterofficeError: (state) => {
      state.RegisterofficeError = {};
    },
    setSlavendordateError: (state, action) => {
      state.SlavendordateError = { name: action.payload };
    },
    clearSlavendordateError: (state) => {
      state.SlavendordateError = {};
    },
    setSelectproductsError: (state, action) => {
        state.SelectproductsError = { name: action.payload };
      },
      clearSelectproductsError: (state) => {
        state.SelectproductsError = {};
      },
    setSlafromdateError: (state, action) => {
      state.SlafromdateError = { name: action.payload };
    },
    clearSlafromdateError: (state) => {
      state.SlafromdateError = {};
    },
    setSlatodateError: (state, action) => {
      state.SlatodateError = { name: action.payload };
    },
    clearSlatodateError: (state) => {
      state.SlatodateError = {};
    },
    setAdvancepaidError: (state, action) => {
      state.AdvancepaidError = { name: action.payload };
    },
    clearAdvancepaidError: (state) => {
      state.AdvancepaidError = {};
    },
    setAadhaarcopyError: (state, action) => {
        state.AadhaarcopyError = { name: action.payload };
      },
      clearAadhaarcopyError: (state) => {
        state.AadhaarcopyError = {};
      },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload };
    },
    clearCityError: (state) => {
      state.CityError = {};
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload };
    },
    clearStateError: (state) => {
      state.StateError = {};
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload };
    },
    clearZoneError: (state) => {
      state.ZoneError = {};
    },
    setPercaseError: (state, action) => {
      state.PercaseError = { name: action.payload };
    },
    clearPercaseError: (state) => {
      state.PercaseError = {};
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload };
    },
    clearPincodeError: (state) => {
      state.PincodeError = {};
    },
    setGstnumberError: (state, action) => {
      state.GstnumberError = { name: action.payload };
    },
    clearGstnumberError: (state) => {
      state.GstnumberError = {};
    },
    setGstcopyError: (state, action) => {
      state.GstcopyError = { name: action.payload };
    },
    clearGstcopyError: (state) => {
      state.GstcopyError = {};
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload };
    },
    clearPancardError: (state) => {
      state.PancardError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setGridNameError: (state, action) => {
      state.GridNameError = { name: action.payload };
    },
    clearGridNameError: (state) => {
      state.GridNameError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    }
  }
});

export const {
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setGridNameError,
  clearGridNameError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setGstcopyError,
  clearGstcopyError,
  setGstnumberError,
  clearGstnumberError,
  setPincodeError,
  clearPincodeError,
  setPercaseError,
  clearPercaseError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setRegisterofficeError,
  clearRegisterofficeError,
  setSlavendordateError,
  clearSlavendordateError,
  setSlafromdateError,
  clearSlafromdateError,
  setSlatodateError,
  clearSlatodateError,
  setAdvancepaidError,
  clearAdvancepaidError,
  setAadhaarcopyError,
  clearAadhaarcopyError,
  setSelectproductsError,
  clearSelectproductsError,
  setNameError,
  clearNameError
} = clientSlice.actions;

export default clientSlice.reducer;
