import React from 'react';
import {
  useNavigate,
  Outlet
} from 'react-router-dom';
import Cookies from 'js-cookie';
import { Container } from 'react-bootstrap';

function AuthLayout({ setAuthenticated }) {
  const navigate = useNavigate();

  if (Cookies.get('pageSignature')) {
    setAuthenticated(true);
    navigate('/admin/dashboard');
  }

  return (
    <main className='main-content mt-0'>
      <section>
        <div className='page-header loginHeight'>
          <Container>
            <Outlet />
          </Container>
        </div>
      </section>
    </main>
  );
}

export default AuthLayout;
