import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    NameError: '',
    AddressError: '',
    CityError: '',
    StateError: '',
    ZoneError: '',
    CountryError: '',
    PincodeError: '',
    WtappnumberError: '',
    GstcopyError: '',
    PancardError: '',
    PancopyError: '',
    GridNameError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    GridgeolocationError: '',
    PersonalemailError: '',
    Personalphone1Error: '',
    Personalphone2Error: '',
    GenderError: '',
    PermittedusersError: ''
  },
  reducers: {
    setPersonalemailError: (state, action) => {
      state.PersonalemailError = { name: action.payload };
    },
    clearPersonalemailError: (state) => {
      state.PersonalemailError = {};
    },
    setPersonalphone1Error: (state, action) => {
      state.Personalphone1Error = { name: action.payload };
    },
    clearPersonalphone1Error: (state) => {
      state.Personalphone1Error = {};
    },
    setPersonalphone2Error: (state, action) => {
      state.Personalphone2Error = { name: action.payload };
    },
    clearPersonalphone2Error: (state) => {
      state.Personalphone2Error = {};
    },
    setGenderError: (state, action) => {
      state.GenderError = { name: action.payload };
    },
    clearGenderError: (state) => {
      state.GenderError = {};
    },
    setNameError: (state, action) => {
      state.NameError = { name: action.payload };
    },
    clearNameError: (state) => {
      state.NameError = {};
    },
    setAddressError: (state, action) => {
      state.AddressError = { name: action.payload };
    },
    clearAddressError: (state) => {
      state.AddressError = {};
    },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload };
    },
    clearCityError: (state) => {
      state.CityError = {};
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload };
    },
    clearStateError: (state) => {
      state.StateError = {};
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload };
    },
    clearZoneError: (state) => {
      state.ZoneError = {};
    },
    setCountryError: (state, action) => {
      state.CountryError = { name: action.payload };
    },
    clearCountryError: (state) => {
      state.CountryError = {};
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload };
    },
    clearPincodeError: (state) => {
      state.PincodeError = {};
    },
    setWtappnumberError: (state, action) => {
      state.WtappnumberError = { name: action.payload };
    },
    clearWtappnumberError: (state) => {
      state.WtappnumberError = {};
    },
    setGstcopyError: (state, action) => {
      state.GstcopyError = { name: action.payload };
    },
    clearGstcopyError: (state) => {
      state.GstcopyError = {};
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload };
    },
    clearPancardError: (state) => {
      state.PancardError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setGridNameError: (state, action) => {
      state.GridNameError = { name: action.payload };
    },
    clearGridNameError: (state) => {
      state.GridNameError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    }
  }
});

export const {
  setPersonalemailError,
  clearPersonalemailError,
  setPersonalphone1Error,
  clearPersonalphone1Error,
  setPersonalphone2Error,
  clearPersonalphone2Error,
  setGenderError,
  clearGenderError,
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setGridNameError,
  clearGridNameError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setGstcopyError,
  clearGstcopyError,
  setWtappnumberError,
  clearWtappnumberError,
  setPincodeError,
  clearPincodeError,
  setCountryError,
  clearCountryError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setAddressError,
  clearAddressError,
  setNameError,
  clearNameError
} = userSlice.actions;

export default userSlice.reducer;
