import { createSlice } from '@reduxjs/toolkit';

const locationListSlice = createSlice({
  name: 'locationGrid',
  initialState: {
    gridData: [],
    filter: {
      city: '',
      state: '',
      zone: '',
      country: ''
    },
    currentPage: 1,
    itemsPerPage: 10,
    sortOrder: {
      field: '',
      asc: true
    },
    updateFlag: false
  },
  reducers: {
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    createLocation: (state, action) => {
      state.gridData.push(action.payload);
    },
    updateLocation: (state, action) => {
      const updatedLocation = action.payload;
      const index = state.gridData.findIndex((loc) => loc.id === updatedLocation.id);
      if (index !== -1) {
        state.gridData[index] = updatedLocation;
      }
    },
    deleteLocation: (state, action) => {
      const locationId = action.payload;
      state.gridData = state.gridData.filter((loc) => loc.id !== locationId);
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setUpdateFlag: (state) => {
      state.updateFlag = true;
    },
    resetUpdateFlag: (state) => {
      state.updateFlag = false;
    }
  }
});

export const {
  setGridData,
  setFilter,
  setCurrentPage,
  setSortOrder,
  setUpdateFlag,
  resetUpdateFlag,
  createLocation,
  updateLocation,
  deleteLocation
} = locationListSlice.actions;

export default locationListSlice.reducer;
