  import { createSlice } from '@reduxjs/toolkit';

  const locationSlice = createSlice({
    name: 'location',
    initialState: {
      formData: {
        country: '',
        zone: '',
        state: '',
        city: ''
      },
      formErrors: {},
      dropdownData: {
        country: [],
        zone: [],
        state: [],
        city: []
      }
    },
    reducers: {
      setFormData: (state, action) => {
        state.formData = action.payload;
      },
      resetFormData: (state) => {
        state.formData = {
          country: '',
          zone: '',
          state: '',
          city: ''
        };
      },
      setFormErrors: (state, action) => {
        state.formErrors = action.payload;
      },
      setDropdownData: (state, action) => {
        state.dropdownData = action.payload;
      },
      resetLocationForm: (state) => {
        state.formData = {
          country: '',
          zone: '',
          state: '',
          city: ''
        };
      }
    }
  });

  export const {
    setFormData,
    resetFormData,
    setFormErrors,
    setDropdownData,
    resetLocationForm
  } = locationSlice.actions;

  export default locationSlice.reducer;
