import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    ClientError: '',
    ProductError: '',
    NoofcaseError: '',
    SubscriptiontypeError: '',
    SubscriptionperiodError: '',
    SubscritioncostError: '',
    PaymentError: '',
    PaymentdateError: '',
    PaymentamountError: '',
    PaymentmodeError: '',
    ChequenumberError: '',
    ChequecopyError: '',
    TransactionrefnumberError: '',
    Client1Error: '',
    Product1Error: '',
    Subscriptiontype1Error: '',
    Client2Error: '',
    Product2Error: '',
    Subscriptiontype2Error: '',
    StatusError: '',
    GridgeolocationError: '',
    RemarkError: ''
  },
  reducers: {
    setClientError: (state, action) => {
      state.ClientError = { name: action.payload };
    },
    clearClientError: (state) => {
      state.ClientError = {};
    },
    setProductError: (state, action) => {
      state.ProductError = { name: action.payload };
    },
    clearProductError: (state) => {
      state.ProductError = {};
    },
    setClient1Error: (state, action) => {
      state.Client1Error = { name: action.payload };
    },
    clearClient1Error: (state) => {
      state.Client1Error = {};
    },
    setStatusError: (state, action) => {
      state.StatusError = { name: action.payload };
    },
    clearStatusError: (state) => {
      state.StatusError = {};
    },
    setProduct1Error: (state, action) => {
      state.Product1Error = { name: action.payload };
    },
    clearProduct1Error: (state) => {
      state.Product1Error = {};
    },
    setSubscriptiontype1Error: (state, action) => {
      state.Subscriptiontype1Error = { name: action.payload };
    },
    clearSubscriptiontype1Error: (state) => {
      state.Subscriptiontype1Error = {};
    },
    setClient2Error: (state, action) => {
      state.Client2Error = { name: action.payload };
    },
    clearClient2Error: (state) => {
      state.Client2Error = {};
    },
    setTransactionrefnumberError: (state, action) => {
      state.TransactionrefnumberError = { name: action.payload };
    },
    clearTransactionrefnumberError: (state) => {
      state.TransactionrefnumberError = {};
    },
    setNoofcaseError: (state, action) => {
      state.NoofcaseError = { name: action.payload };
    },
    clearNoofcaseError: (state) => {
      state.NoofcaseError = {};
    },
    setSubscriptiontypeError: (state, action) => {
      state.SubscriptiontypeError = { name: action.payload };
    },
    clearSubscriptiontypeError: (state) => {
      state.SubscriptiontypeError = {};
    },
    setSubscriptionperiodError: (state, action) => {
      state.SubscriptionperiodError = { name: action.payload };
    },
    clearSubscriptionperiodError: (state) => {
      state.SubscriptionperiodError = {};
    },
    setSubscritioncostError: (state, action) => {
      state.SubscritioncostError = { name: action.payload };
    },
    clearSubscritioncostError: (state) => {
      state.SubscritioncostError = {};
    },
    setPaymentError: (state, action) => {
      state.PaymentError = { name: action.payload };
    },
    clearPaymentError: (state) => {
      state.PaymentError = {};
    },
    setPaymentdateError: (state, action) => {
      state.PaymentdateError = { name: action.payload };
    },
    clearPaymentdateError: (state) => {
      state.PaymentdateError = {};
    },
    setPaymentamountError: (state, action) => {
      state.PaymentamountError = { name: action.payload };
    },
    clearPaymentamountError: (state) => {
      state.PaymentamountError = {};
    },
    setPaymentmodeError: (state, action) => {
      state.PaymentmodeError = { name: action.payload };
    },
    clearPaymentmodeError: (state) => {
      state.PaymentmodeError = {};
    },
    setChequenumberError: (state, action) => {
      state.ChequenumberError = { name: action.payload };
    },
    clearChequenumberError: (state) => {
      state.ChequenumberError = {};
    },
    setChequecopyError: (state, action) => {
      state.ChequecopyError = { name: action.payload };
    },
    clearChequecopyError: (state) => {
      state.ChequecopyError = {};
    },
    setProduct2Error: (state, action) => {
      state.Product2Error = { name: action.payload };
    },
    clearProduct2Error: (state) => {
      state.Product2Error = {};
    },
    setSubscriptiontype2Error: (state, action) => {
      state.Subscriptiontype2Error = { name: action.payload };
    },
    clearSubscriptiontype2Error: (state) => {
      state.Subscriptiontype2Error = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setRemarkError: (state, action) => {
      state.RemarkError = { name: action.payload };
    },
    clearRemarkError: (state) => {
      state.RemarkError = {};
    }
  }
});

export const {
  setPaymentmodeError,
  clearPaymentmodeError,
  setChequenumberError,
  clearChequenumberError,
  setChequecopyError,
  clearChequecopyError,
  setProduct2Error,
  clearProduct2Error,
  setSubscriptiontype2Error,
  clearSubscriptiontype2Error,
  setGridgeolocationError,
  clearGridgeolocationError,
  setRemarkError,
  clearRemarkError,
  setPaymentamountError,
  clearPaymentamountError,
  setPaymentdateError,
  clearPaymentdateError,
  setPaymentError,
  clearPaymentError,
  setSubscritioncostError,
  clearSubscritioncostError,
  setSubscriptionperiodError,
  clearSubscriptionperiodError,
  setSubscriptiontypeError,
  clearSubscriptiontypeError,
  setNoofcaseError,
  clearNoofcaseError,
  setProductError,
  clearProductError,
  setClient1Error,
  clearClient1Error,
  setProduct1Error,
  clearProduct1Error,
  setSubscriptiontype1Error,
  clearSubscriptiontype1Error,
  setClient2Error,
  clearClient2Error,
  setTransactionrefnumberError,
  clearTransactionrefnumberError,
  setStatusError,
  clearStatusError,
  setClientError,
  clearClientError
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
