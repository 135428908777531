import { createSlice } from '@reduxjs/toolkit';

const billSlice = createSlice({
  name: 'bill',
  initialState: {
    ClientError: '',
    ProductError: '',
    NoofcaseError: '',
    SubscriptiontypeError: '',
    SubscriptionperiodError: '',
    SubscritioncostError: '',
    ReasonError: '',
    PaymentmodeError: '',
    Periodfrom2Error: '',
    GstError: '',
    PeriodfromError: '',
    ChequecopyError: '',
    TransactionrefnumberError: '',
    Client1Error: '',
    Product1Error: '',
    Subscriptiontype1Error: '',
    Client2Error: '',
    Product2Error: '',
    TotalamtError: '',
    StatusError: '',
    InvoicedateError: '',
    RemarkError: '',
    ChequenumberError: '',
    Periodfrom1Error: ''
  },
  reducers: {
    setChequenumberError: (state, action) => {
      state.ChequenumberError = { name: action.payload };
    },
    clearChequenumberError: (state) => {
      state.ChequenumberError = {};
    },
    setRemarkError: (state, action) => {
      state.RemarkError = { name: action.payload };
    },
    clearRemarkError: (state) => {
      state.RemarkError = {};
    },
    setProductError: (state, action) => {
      state.ProductError = { name: action.payload };
    },
    clearProductError: (state) => {
      state.ProductError = {};
    },
    setClient1Error: (state, action) => {
      state.Client1Error = { name: action.payload };
    },
    clearClient1Error: (state) => {
      state.Client1Error = {};
    },
    setStatusError: (state, action) => {
      state.StatusError = { name: action.payload };
    },
    clearStatusError: (state) => {
      state.StatusError = {};
    },
    setProduct1Error: (state, action) => {
      state.Product1Error = { name: action.payload };
    },
    clearProduct1Error: (state) => {
      state.Product1Error = {};
    },
    setSubscriptiontype1Error: (state, action) => {
      state.Subscriptiontype1Error = { name: action.payload };
    },
    clearSubscriptiontype1Error: (state) => {
      state.Subscriptiontype1Error = {};
    },
    setClient2Error: (state, action) => {
      state.Client2Error = { name: action.payload };
    },
    clearClient2Error: (state) => {
      state.Client2Error = {};
    },
    setTransactionrefnumberError: (state, action) => {
      state.TransactionrefnumberError = { name: action.payload };
    },
    clearTransactionrefnumberError: (state) => {
      state.TransactionrefnumberError = {};
    },
    setNoofcaseError: (state, action) => {
      state.NoofcaseError = { name: action.payload };
    },
    clearNoofcaseError: (state) => {
      state.NoofcaseError = {};
    },
    setSubscriptiontypeError: (state, action) => {
      state.SubscriptiontypeError = { name: action.payload };
    },
    clearSubscriptiontypeError: (state) => {
      state.SubscriptiontypeError = {};
    },
    setSubscriptionperiodError: (state, action) => {
      state.SubscriptionperiodError = { name: action.payload };
    },
    clearSubscriptionperiodError: (state) => {
      state.SubscriptionperiodError = {};
    },
    setSubscritioncostError: (state, action) => {
      state.SubscritioncostError = { name: action.payload };
    },
    clearSubscritioncostError: (state) => {
      state.SubscritioncostError = {};
    },
    setReasonError: (state, action) => {
      state.ReasonError = { name: action.payload };
    },
    clearReasonError: (state) => {
      state.ReasonError = {};
    },
    setPaymentmodeError: (state, action) => {
      state.PaymentmodeError = { name: action.payload };
    },
    clearPaymentmodeError: (state) => {
      state.PaymentmodeError = {};
    },
    setPeriodfrom2Error: (state, action) => {
      state.Periodfrom2Error = { name: action.payload };
    },
    clearPeriodfrom2Error: (state) => {
      state.Periodfrom2Error = {};
    },
    setGstError: (state, action) => {
      state.GstError = { name: action.payload };
    },
    clearGstError: (state) => {
      state.GstError = {};
    },
    setPeriodfromError: (state, action) => {
      state.PeriodfromError = { name: action.payload };
    },
    clearPeriodfromError: (state) => {
      state.PeriodfromError = {};
    },
    setChequecopyError: (state, action) => {
      state.ChequecopyError = { name: action.payload };
    },
    clearChequecopyError: (state) => {
      state.ChequecopyError = {};
    },
    setProduct2Error: (state, action) => {
      state.Product2Error = { name: action.payload };
    },
    clearProduct2Error: (state) => {
      state.Product2Error = {};
    },
    setTotalamtError: (state, action) => {
      state.TotalamtError = { name: action.payload };
    },
    clearTotalamtError: (state) => {
      state.TotalamtError = {};
    },
    setInvoicedateError: (state, action) => {
      state.InvoicedateError = { name: action.payload };
    },
    clearInvoicedateError: (state) => {
      state.InvoicedateError = {};
    },
    setPeriodfrom1Error: (state, action) => {
      state.Periodfrom1Error = { name: action.payload };
    },
    clearPeriodfrom1Error: (state) => {
      state.Periodfrom1Error = {};
    }
  }
});

export const {
  setChequenumberError,
  clearChequenumberError,
  setGstError,
  clearGstError,
  setRemarkError,
  clearRemarkError,
  setPeriodfromError,
  clearPeriodfromError,
  setChequecopyError,
  clearChequecopyError,
  setProduct2Error,
  clearProduct2Error,
  setTotalamtError,
  clearTotalamtError,
  setInvoicedateError,
  clearInvoicedateError,
  setPeriodfrom1Error,
  clearPeriodfrom1Error,
  setPeriodfrom2Error,
  clearPeriodfrom2Error,
  setPaymentmodeError,
  clearPaymentmodeError,
  setReasonError,
  clearReasonError,
  setSubscritioncostError,
  clearSubscritioncostError,
  setSubscriptionperiodError,
  clearSubscriptionperiodError,
  setSubscriptiontypeError,
  clearSubscriptiontypeError,
  setNoofcaseError,
  clearNoofcaseError,
  setProductError,
  clearProductError,
  setClient1Error,
  clearClient1Error,
  setProduct1Error,
  clearProduct1Error,
  setSubscriptiontype1Error,
  clearSubscriptiontype1Error,
  setClient2Error,
  clearClient2Error,
  setTransactionrefnumberError,
  clearTransactionrefnumberError,
  setStatusError,
  clearStatusError,
  setClientError,
  clearClientError
} = billSlice.actions;

export default billSlice.reducer;
