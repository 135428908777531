import { createSlice } from '@reduxjs/toolkit';

const newclaimSlice = createSlice({
  name: 'newclaim',
  initialState: {
    PolicynumberError: '',
    VehicleregumberError: '',
    TypeofvehicleError: '',
    CustomernameError: '',
    CustomermobileError: '',
    CustomeremailError: '',
    AccidentonError: '',
    MakeError: '',
    ModelError: '',
    InsurerError: '',
    PancopyError: '',
    PolicyfromError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    GridgeolocationError: '',
    PersonalemailError: '',
    Personalphone1Error: '',
    Personalphone2Error: '',
    GenderError: '',
    PermittedusersError: ''
  },
  reducers: {
    setPersonalemailError: (state, action) => {
      state.PersonalemailError = { name: action.payload };
    },
    clearPersonalemailError: (state) => {
      state.PersonalemailError = {};
    },
    setPersonalphone1Error: (state, action) => {
      state.Personalphone1Error = { name: action.payload };
    },
    clearPersonalphone1Error: (state) => {
      state.Personalphone1Error = {};
    },
    setPersonalphone2Error: (state, action) => {
      state.Personalphone2Error = { name: action.payload };
    },
    clearPersonalphone2Error: (state) => {
      state.Personalphone2Error = {};
    },
    setGenderError: (state, action) => {
      state.GenderError = { name: action.payload };
    },
    clearGenderError: (state) => {
      state.GenderError = {};
    },
    setPolicynumberError: (state, action) => {
      state.PolicynumberError = { name: action.payload };
    },
    clearPolicynumberError: (state) => {
      state.PolicynumberError = {};
    },
    setVehicleregumberError: (state, action) => {
      state.VehicleregumberError = { name: action.payload };
    },
    clearVehicleregumberError: (state) => {
      state.VehicleregumberError = {};
    },
    setTypeofvehicleError: (state, action) => {
      state.TypeofvehicleError = { name: action.payload };
    },
    clearTypeofvehicleError: (state) => {
      state.TypeofvehicleError = {};
    },
    setCustomernameError: (state, action) => {
      state.CustomernameError = { name: action.payload };
    },
    clearCustomernameError: (state) => {
      state.CustomernameError = {};
    },
    setCustomermobileError: (state, action) => {
      state.CustomermobileError = { name: action.payload };
    },
    clearCustomermobileError: (state) => {
      state.CustomermobileError = {};
    },
    setCustomeremailError: (state, action) => {
      state.CustomeremailError = { name: action.payload };
    },
    clearCustomeremailError: (state) => {
      state.CustomeremailError = {};
    },
    setAccidentonError: (state, action) => {
      state.AccidentonError = { name: action.payload };
    },
    clearAccidentonError: (state) => {
      state.AccidentonError = {};
    },
    setMakeError: (state, action) => {
      state.MakeError = { name: action.payload };
    },
    clearMakeError: (state) => {
      state.MakeError = {};
    },
    setModelError: (state, action) => {
      state.ModelError = { name: action.payload };
    },
    clearModelError: (state) => {
      state.ModelError = {};
    },
    setInsurerError: (state, action) => {
      state.InsurerError = { name: action.payload };
    },
    clearInsurerError: (state) => {
      state.InsurerError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setPolicyfromError: (state, action) => {
      state.PolicyfromError = { name: action.payload };
    },
    clearPolicyfromError: (state) => {
      state.PolicyfromError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    }
  }
});

export const {
  setPersonalemailError,
  clearPersonalemailError,
  setPersonalphone1Error,
  clearPersonalphone1Error,
  setPersonalphone2Error,
  clearPersonalphone2Error,
  setGenderError,
  clearGenderError,
  setInsurerError,
  clearInsurerError,
  setPancopyError,
  clearPancopyError,
  setPolicyfromError,
  clearPolicyfromError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setModelError,
  clearModelError,
  setMakeError,
  clearMakeError,
  setAccidentonError,
  clearAccidentonError,
  setCustomeremailError,
  clearCustomeremailError,
  setCustomermobileError,
  clearCustomermobileError,
  setCustomernameError,
  clearCustomernameError,
  setTypeofvehicleError,
  clearTypeofvehicleError,
  setVehicleregumberError,
  clearVehicleregumberError,
  setPolicynumberError,
  clearPolicynumberError
} = newclaimSlice.actions;

export default newclaimSlice.reducer;
