import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null
  },
  reducers: {
    loginSuccess: (state, action) => {
      console.log('Login', action);
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    resetPasswordSuccess: (state, action) => {
    },
    resetPasswordFailure: (state, action) => {
    }
  }
});

export const {
  loginSuccess,
  loginFailure,
  setUser,
  resetPasswordSuccess,
  resetPasswordFailure
} = authSlice.actions;
export default authSlice.reducer;
