import { createSlice } from '@reduxjs/toolkit';

const forgetSlice = createSlice({
  name: 'forget',
  initialState: {
    forgotPasswordRequest: false,
    forgotPasswordSuccess: false,
    forgotPasswordFailure: false
  },
  reducers: {
    forgotPasswordRequest: (state) => {
      state.forgotPasswordRequest = true;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordFailure = false;
    },
    forgotPasswordSuccess: (state) => {
      state.forgotPasswordRequest = false;
      state.forgotPasswordSuccess = true;
      state.forgotPasswordFailure = false;
    },
    forgotPasswordFailure: (state) => {
      state.forgotPasswordRequest = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordFailure = true;
    }
  }
});

export const {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure
} = forgetSlice.actions;
export default forgetSlice.reducer;
